.table-container {
  height: 500px;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.table-container::-webkit-scrollbar {
  display: none;
}
